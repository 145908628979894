import axios from 'axios'
import router from '@/router'

export default {
    name: 'AdminStore',
    namespaced: true,
    state: {
        fetchingMembers: false,
        membersFetched: false,
        storeMembers: [],
        profilesFetched: false,
        storeProfiles: [],
        productOptsFetched: false,
        staffProductOptsFetched: false,
        productOptions: {},
        staffProductOptions: {},
        countriesFetched: false,
        countries: [],
        shippingRatesFetched: false,
        shippingRates: [],
        invoiceDetailsFetched: false,
        invoicingDetails: {country: {}},

        fetchingProducts: false,
        moreProductsAvailable: false,
        nextProductUrl: null,
        prodsFetched: false,
        products: [],
        totalProdCount: null,
        productFilters: null,

        fetchingLeaseProducts: false,
        moreLeaseProductsAvailable: false,
        nextLeaseProductUrl: null,
        leaseProdsFetched: false,
        leaseProducts: [],
        totalLeaseProdCount: null,
        leaseProductFilters: null,
        optionsFetched: false,
        managementOptions: {},

        campaigns: [],
        fetchingCampaigns: false,
        moreCampaignsAvailable: false,
        campaignsFetched: false,
        nextCampaignUrl: null,
        totalCampaignCount: null,

        fetchingCampaignProducts: false,
        campaignProductsFetched: false,
        campaignProducts: [],

        uploadingPrintFile: false,
        printFileName: null,
        printFileProgress: 0,
        printFileRequest: null,

        campaignTemplates: [],
        fetchingCampTemplates: false,
        moreCampTemplatesAvailable: false,
        campTemplatesFetched: false,
        nextCampTemplateUrl: null,
        totalCampTemplateCount: null,

        suppliers: [],
        fetchingSuppliers: false,
        suppliersFetched: false,
        supplierFilters: null,

        fetchingCarriers: false,
        fetchingStoreCarriers: false,
    },
    mutations: {
        updateFetchingMembers(state, status) {
            state.fetchingMembers = status;
        },
        updateMembers(state, members) {
            state.storeMembers = members;
            state.membersFetched = true;
        },
        updateProfiles(state, profiles) {
            state.storeProfiles = profiles;
            state.storeProfiles.sort((a, b) => (a.user.last_name.toLowerCase() > b.user.last_name.toLowerCase()) ? 1 : -1);
            state.profilesFetched = true;
        },
        updateProductOptions(state, options) {
            state.productOptions = options;
            state.productOptsFetched = true;
        },
        updateStaffProductOptions(state, options) {
            state.staffProductOptions = options;
            state.staffProductOptsFetched = true;
        },
        updateCountries(state, countries) {
            state.countries = countries;
            state.countriesFetched = true;
        },
        updateShippingRates(state, shippingRates) {
            state.shippingRates = shippingRates.rates_list;
            state.shippingRatesFetched = true;
        },
        updateInvoiceDetails(state, details) {
            if (details) {
                state.invoicingDetails = details;
            }
            state.invoiceDetailsFetched = true;
        },
        clearData(state) {
            // Clear store related data for example after changing active store
            state.fetchingMembers = false;
            state.membersFetched = false;
            state.storeMembers = [];
            state.profilesFetched = false;
            state.storeProfiles = [];
            state.invoiceDetailsFetched = false;
            state.invoicingDetails = {country: {}};
        },

        updateProducts(state, response) {
            state.products = [...state.products, ...response.data.results];
            state.prodsFetched = true;
            state.moreProductsAvailable = response.data.next ? true : false;
            state.totalProdCount = response.data.count;
            state.nextProductUrl = response.data.next;
            state.fetchingProducts = false;
        },
        addProduct(state, p) {
            state.products = [p, ...state.products];
        },
        updateProduct(state, updatedProduct) {
            state.products = [...state.products.map(item => item.id !== updatedProduct.id ? item : {...item, ...updatedProduct})]
        },
        removeProduct(state, updatedProduct) {
            state.products = state.products.filter(p => p.id !== updatedProduct.id);
        },
        clearProducts(state) {
            state.prodsFetched = false;
            state.moreProductsAvailable = null;
            state.products = [];
            state.totalProdCount = null;
            state.nextProductUrl = null;
            state.productFilters = null;
        },
        clearProductFilters(state) {
            state.productFilters = null;
        },
        reorderProductList(state, data) {
            state.products = data;
        },
        updateLeaseProducts(state, response) {
            state.leaseProducts = [...state.leaseProducts, ...response.data.results];
            state.leaseProdsFetched = true;
            state.moreLeaseProductsAvailable = response.data.next ? true : false;
            state.totalLeaseProdCount = response.data.count;
            state.nextLeaseProductUrl = response.data.next;
            state.fetchingLeaseProducts = false;
        },
        addLeaseProduct(state, lp) {
            state.leaseProducts = [lp, ...state.leaseProducts];
        },
        updateLeaseProduct(state, updatedProduct) {
            state.leaseProducts = [...state.leaseProducts.map(item => item.id !== updatedProduct.id ? item : {...item, ...updatedProduct})]
        },
        clearLeaseProducts(state) {
            state.leaseProdsFetched = false;
            state.moreLeaseProductsAvailable = null;
            state.leaseProducts = [];
            state.totalLeaseProdCount = null;
            state.nextLeaseProductUrl = null;
            state.leaseProductFilters = null;
        },
        clearLPFilters(state) {
            state.leaseProductFilters = null;
        },
        setOptions(state, options) {
            state.managementOptions = options;
        },
        updateCampaigns(state, response) {
            state.campaigns = [...state.campaigns, ...response.data.results];
            state.campaignsFetched = true;
            state.moreCampaignsAvailable = response.data.next ? true : false;
            state.totalCampaignCount = response.data.count;
            state.nextCampaignUrl = response.data.next;
            state.fetchingCampaigns = false;
        },
        clearCampaigns(state) {
            state.campaignsFetched = false;
            state.moreCampaignsAvailable = null;
            state.campaigns = [];
            state.totalCampaignCount = null;
            state.nextCampaignUrl = null;
        },
        addCampaign(state, newCampaign) {
            state.campaigns = [newCampaign, ...state.campaigns];
        },
        updateCampaign(state, updatedCampaign) {
            state.campaigns = state.campaigns.map(item => item.id !== updatedCampaign.id ? item : {...item, ...updatedCampaign});
        },
        updateCampaignProducts(state, response) {
            // Filter only published products for campaigns
            let published = response.data.results.filter(p => p.status == 2)
            state.campaignProducts = [...published];
            state.campaignProductsFetched = true;
            state.fetchingCampaignProducts = false;
        },
        clearCampaignProducts(state) {
            state.campaignProducts = [];
            state.campaignProductsFetched = false;
            state.fetchingCampaignProducts = false;
        },
        updatePrintFileProgress(state, percent) {
            if (!state.uploadingPrintFile) {
                state.uploadingPrintFile = true;
            }
            state.printFileProgress = percent;
        },
        startPrintFileUpload(state, data) {
            state.printFileName = data.filename;
            state.printFileRequest = data.request;
        },
        finishPrintFileUpload(state) {
            state.uploadingPrintFile = false;
            state.printFileProgress = 0;
            state.printFileName = null;
            state.printFileRequest = null;
        },
        updateCampaignTemplates(state, response) {
            state.campaignTemplates = [...state.campaignTemplates, ...response.data.results];
            state.campTemplatesFetched = true;
            state.moreCampTemplatesAvailable = response.data.next ? true : false;
            state.totalCampTemplateCount = response.data.count;
            state.nextCampTemplateUrl = response.data.next;
            state.fetchingCampTemplates = false;
        },
        clearCampaignTemplates(state) {
            state.campTemplatesFetched = false;
            state.moreCampTemplatesAvailable = null;
            state.campaignTemplates = [];
            state.totalCampTemplateCount = null;
            state.nextCampTemplateUrl = null;
        },
        addCampaignTemplate(state, newTemplate) {
            state.campaignTemplates = [newTemplate, ...state.campaignTemplates];
        },
        updateCampaignTemplate(state, updatedTemplate) {
            state.campaignTemplates = state.campaignTemplates.map(item => item.id !== updatedTemplate.id ? item : {...item, ...updatedTemplate});
        },
        clearSuppliers(state) {
            state.suppliers = []
            state.fetchingSuppliers = false
            state.suppliersFetched = false
            state.supplierFilters = null
        },
        updateSuppliers(state, response) {
            state.suppliers = [...state.suppliers, ...response.data]
            state.suppliersFetched = true
            state.fetchingProducts = false
        },
    },
    getters: {
        products: state => state.products,
        fetchingProducts: state => state.fetchingProducts,
        leaseProducts: state => state.leaseProducts,
        fetchingLeaseProducts: state => state.fetchingLeaseProducts,
        storeMembers: state => state.storeMembers,
        membersFetched: state => state.membersFetched,
        storeProfiles: state => state.storeProfiles,
        profilesFetched: state => state.profilesFetched,
        productOptsFetched: state => state.productOptsFetched,
        staffProductOptsFetched: state => state.staffProductOptsFetched,
        carriersFetched: state => state.carriersFetched,
        fetchingCarriers: state => state.fetchingCarriers,
        storeCarriersFetched: state => state.storeCarriersFetched,
        fetchingStoreCarriers: state => state.fetchingStoreCarriers,
        productOptions: state => state.productOptions,
        storeCarrierStatuses: state => state.productOptions['store_carrier_statuses'],
        staffProductOptions: state => state.staffProductOptions,
        countriesFetched: state => state.countriesFetched,
        countries: state => state.countries,
        shippingRates: state => state.shippingRates,
        invoiceDetailsFetched: state => state.invoiceDetailsFetched,
        invoicingDetails: state => state.invoicingDetails,
        storeMinCharge: (state, getters, rootState, rootGetters) => {
            if (!state.productOptsFetched) {
                return null;
            }
            if (!rootGetters.activeStore || !rootGetters.activeStore.currency_display) {
                return null;
            }
            const storeCurr = state.productOptions.main_currencies.includes(rootGetters.activeStore.currency_display) ? rootGetters.activeStore.currency_display : state.productOptions.main_currencies[0];
            const currCharge = state.productOptions.min_charges.find(c => c[0] == storeCurr)
            if (currCharge) {
                return currCharge[1];
            }
            return null;
        },
        uploadingPrintFile: state => state.uploadingPrintFile,
        printFileProgress: state => state.printFileProgress,
        printFileName: state => state.printFileName,
        campaignTemplates: state => state.campaignTemplates,
        defaultCampTemplate: (state) => {
            if (!state.campTemplatesFetched) {
                return null;
            }
            const default_template = state.campaignTemplates.find(t => t.public_default == true);
            if (default_template) {
                return default_template.id;
            }
            return null;
        },
        suppliers: state => state.suppliers,
        suppliersFetched: state => state.suppliersFetched,
        fetchingSuppliers: state => state.fetchingSuppliers,
    },
    actions: {
        fetchManagementOptions({commit, state, rootGetters}) {
            if (!rootGetters.activeStore || !rootGetters.activeStore.id) {
                return;
            }
            if (!state.fetchingOptions) {
                state.fetchingOptions = true;
                state.optionsFetched = true;
                axios({
                    url: process.env.VUE_APP_API_URL + "/api/stores/" + rootGetters.activeStore.id + "/managementfilteroptions/",
                    method: 'GET'
                })
                    .then(resp => {
                        const options = resp.data
                        state.fetchingOptions = false;
                        commit('setOptions', options);
                    })
                    .catch(function () {
                        state.fetchingOptions = false;
                        state.optionsFetched = false;
                    })
            }
        },
        fetchMembers({commit, dispatch, state, rootGetters}) {
            if (rootGetters.activeStore && rootGetters.activeStore.id) {
                if (state.fetchingMembers) {
                    return;
                } else {
                    commit('updateFetchingMembers', true);
                }
                axios({
                    url: process.env.VUE_APP_API_URL + "/api/stores/" + rootGetters.activeStore.id + "/members/",
                    method: 'GET'
                })
                    .then(resp => {
                        const members = resp.data
                        commit('updateMembers', members);
                        dispatch('fetchProfiles');
                    })
                    .catch(function () {
                        commit('updateFetchingMembers', false);
                    })
            }
        },
        fetchProfiles({commit, rootGetters}) {
            if (rootGetters.activeStore && rootGetters.activeStore.id) {
                axios({
                    url: process.env.VUE_APP_API_URL + "/api/users/store/" + rootGetters.activeStore.id + "/",
                    method: 'GET'
                })
                    .then(resp => {
                        const profiles = resp.data;
                        commit('updateProfiles', profiles);
                        commit('updateFetchingMembers', false);
                    })
                    .catch(function () {
                        commit('updateFetchingMembers', false);
                    })
            }
        },
        removeMember({commit, state}, data) {
            const profiles = state.storeProfiles.filter(p => p.id != data.profile_id);
            commit('updateProfiles', profiles);
            const members = state.storeMembers.filter(m => m.id != data.member_id);
            commit('updateMembers', members);
        },
        fetchProductOptions({commit}) {
            axios({url: process.env.VUE_APP_API_URL + "/api/stores/products/options/", method: 'GET'})
                .then(resp => {
                    const options = resp.data;
                    commit('updateProductOptions', options);
                })
                .catch(function () {
                })
        },
        fetchStaffProductOptions({commit, rootGetters}) {
            if (rootGetters.profile && rootGetters.profile.user.is_staff) {
                axios({url: process.env.VUE_APP_API_URL + "/api/stores/products/staff_options/", method: 'GET'})
                    .then(resp => {
                        const options = resp.data;
                        commit('updateStaffProductOptions', options);
                    })
                    .catch(function () {
                    })
            } else {
                commit('updateStaffProductOptions', {});
            }
        },
        fetchCountries({commit}) {
            axios({url: process.env.VUE_APP_API_URL + "/api/stores/addresses/countries/", method: 'GET'})
                .then(resp => {
                    const countries = resp.data;
                    commit('updateCountries', countries);
                })
                .catch(function () {
                })
        },
        async fetchShippingRates({commit, rootGetters}, data) {
            if (!rootGetters.activeStore || !rootGetters.activeStore.id) {
                return;
            }
            try {
                const response = await axios({
                    url: process.env.VUE_APP_API_URL + "/api/stores/" + rootGetters.activeStore.id + "/carriers/get_rates",
                    method: 'POST',
                    data: data
                })
                const fetchedDetails = response.data;
                commit('updateShippingRates', fetchedDetails)
            } catch (error) {
                // Handle any error that occurred during the API call
                //console.error(error);
            }
        },
        clearStoreAdminData({commit}) {
            commit('clearData');
            commit('clearLeaseProducts');
            commit('clearProducts');
            commit('clearCampaigns');
            commit('clearCampaignProducts');
            commit('clearCampaignTemplates');
        },
        fetchInvoiceDetails({commit, rootGetters}) {
            axios({
                url: process.env.VUE_APP_API_URL + "/api/stores/" + rootGetters.activeStore.id + "/invoicing_details/",
                method: 'GET'
            })
                .then(resp => {
                    if (resp.data.length > 0) {
                        commit('updateInvoiceDetails', resp.data[0]);
                    } else {
                        commit('updateInvoiceDetails', null);
                    }
                })
                .catch(function () {
                })
        },
        updateInvoiceDetails({commit}, data) {
            commit('updateInvoiceDetails', data);
        },


        fetchProducts({commit, state, rootGetters}, data) {
            if (state.fetchingProducts) {
                return;
            }
            if (state.prodsFetched && !state.moreProductsAvailable) {
                // No more products available
                return;
            }

            state.fetchingProducts = true;
            // the default page size must be 25
            const pageSize = data.pageSize ? data.pageSize : 25
            let url = process.env.VUE_APP_API_URL + "/api/stores/" + rootGetters.activeStore.id + "/products/?page_size=" + pageSize
            if (data.append) {
                // get more results with same params
                url = state.nextProductUrl;
            } else {
                // not appending so clear everything
                commit('clearProducts');
                if (data.filters) {
                    // not appending ie first batch and including filters --> save filters
                    state.productFilters = data.filters;
                }
            }
            if (data.filters) {
                url = url + '&' + data.filters;
            }

            axios.get(url)
                .then(response => {
                    commit('updateProducts', response);
                })
                .catch(function () {
                    state.fetchingProducts = false;
                })
        },
        addProduct({commit, dispatch}, data) {
            commit('addProduct', data);
            setTimeout(function () {
                dispatch('updateProductThumbs', data);
            }, 2000);
        },
        updateProduct({commit, dispatch}, data) {
            commit('updateProduct', data);
            // Thumbnails are created async, so re-fetch product to get new thumb urls
            setTimeout(function () {
                dispatch('updateProductThumbs', data);
            }, 2000);
        },
        removeProduct({commit}, data) {
            commit('removeProduct', data);
        },
        updateProductThumbs({commit, rootGetters}, productData) {
            axios({
                url: process.env.VUE_APP_API_URL + "/api/stores/" + rootGetters.activeStore.id + "/products/" + productData.id + "/",
                method: 'GET'
            })
                .then(resp => {
                    commit('updateProduct', resp.data);
                })
        },
        clearAndFetchProducts({commit, dispatch, state}, data) {
            if (state.fetchingProducts) {
                return;
            }
            commit('clearProducts');
            dispatch('fetchProducts', data);
        },
        clearProducts({commit}) {
            commit('clearProductFilters');
            commit('clearProducts');
        },
        reorderProductList({commit}, data) {
            commit('reorderProductList', data)
        },
        fetchLeaseProducts({commit, state, rootGetters}, data) {
            if (state.fetchingLeaseProducts) {
                return;
            }
            if (state.leaseProdsFetched && !state.moreLeaseProductsAvailable) {
                // No more lease products available
                return;
            }

            state.fetchingLeaseProducts = true;
            // the default page size must be 25
            const pageSize = data.pageSize ? data.pageSize : 25
            let url = process.env.VUE_APP_API_URL + "/api/stores/" + rootGetters.activeStore.id + "/leaseproducts/?page_size=" + pageSize
            if (data.append) {
                // get more results with same params
                url = state.nextLeaseProductUrl;
            } else if (data.filters) {
                // not appending ie first batch and including filters --> save filters
                state.leaseProductFilters = data.filters;
            }
            if (data.filters) {
                url = url + '&' + data.filters;
            }

            axios.get(url)
                .then(response => {
                    commit('updateLeaseProducts', response);
                })
                .catch(function () {
                    state.fetchingLeaseProducts = false;
                })
        },
        addLeaseProduct({commit, dispatch}, data) {
            commit('addLeaseProduct', data);
            setTimeout(function () {
                dispatch('updateLeaseProductThumbs', data);
            }, 2000);
        },
        updateLeaseProduct({commit, dispatch}, data) {
            commit('updateLeaseProduct', data);
            // Thumbnails are created async, so re-fetch product to get new thumb urls
            setTimeout(function () {
                dispatch('updateLeaseProductThumbs', data);
            }, 2000);
        },
        updateLeaseProductThumbs({commit, rootGetters}, productData) {
            axios({
                url: process.env.VUE_APP_API_URL + "/api/stores/" + rootGetters.activeStore.id + "/leaseproducts/" + productData.id + "/",
                method: 'GET'
            })
                .then(resp => {
                    commit('updateLeaseProduct', resp.data);
                })
        },
        clearAndFetchLPs({commit, dispatch, state}, data) {
            if (state.fetchingLeaseProducts) {
                return;
            }
            commit('clearLeaseProducts');
            dispatch('fetchLeaseProducts', data);
        },
        clearLeaseProducts({commit}) {
            commit('clearLPFilters');
            commit('clearLeaseProducts');
        },
        fetchCampaigns({commit, state, rootGetters}, data = {}) {
            if (state.fetchingCampaigns || !rootGetters.activeStore.id) {
                return;
            }
            if (state.campaignsFetched && !state.moreCampaignsAvailable) {
                // No more campaigns available
                return;
            }

            state.fetchingCampaigns = true;
            // 25 default page size
            const pageSize = data.pageSize ? data.pageSize : 25;
            let url = process.env.VUE_APP_API_URL + "/api/campaigns/store/" + rootGetters.activeStore.id + "/?page_size=" + pageSize
            if (data.append) {
                // get more results with same params
                url = state.nextCampaignUrl;
            } else {
                // not appending so clear everything
                commit('clearCampaigns');
            }

            axios.get(url)
                .then(response => {
                    commit('updateCampaigns', response);
                })
                .catch(function () {
                    state.campaignsFetched = true;
                    state.fetchingCampaigns = false;
                })
        },
        clearCampaigns({commit}) {
            commit('clearCampaigns');
        },
        addCampaign({commit}, data) {
            commit('addCampaign', data)
        },
        updateCampaign({commit}, data) {
            commit('updateCampaign', data)
        },
        fetchProductsForCampaigns({commit, state, rootGetters}) {
            if (state.fetchingCampaignProducts || !rootGetters.activeStore.id) {
                return;
            }
            if (state.campaignProductsFetched) {
                // Products already fetched
                return;
            }

            state.fetchingCampaignProducts = true;
            let url = process.env.VUE_APP_API_URL + "/api/stores/" + rootGetters.activeStore.id + "/products/?page_size=250"
            axios.get(url)
                .then(response => {
                    commit('updateCampaignProducts', response);
                })
                .catch(function () {
                    state.fetchingCampaignProducts = false;
                })
        },
        getPreSignedPostUrl({rootGetters}, data) {
            return new Promise((resolve, reject) => {
                if (!rootGetters.activeStore.id) {
                    reject("No active store available");
                }
                // data should include: uploadType, fileName, productId
                axios({
                    url: process.env.VUE_APP_API_URL + "/api/stores/" + rootGetters.activeStore.id + "/presigned_url/",
                    method: 'POST',
                    data: data
                })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        updatePrintFileProgress({commit}, event) {
            var percent = Math.round((event.loaded / event.total) * 100);
            commit('updatePrintFileProgress', percent)
        },
        uploadToPresignedPostUrl({rootGetters, dispatch, commit}, data) {
            // {presignedPostData: response.data, fileObj: vm.printFileObj}
            return new Promise((resolve, reject) => {
                if (!rootGetters.activeStore.id) {
                    reject("No active store available");
                }
                const formData = new FormData();
                Object.keys(data.presignedPostData.fields).forEach(key => {
                    formData.append(key, data.presignedPostData.fields[key]);
                });
                // Actual file has to be appended last.
                formData.append("file", data.fileObj);
                const xhr = new XMLHttpRequest();
                commit("startPrintFileUpload", {filename: data.fileObj.name, request: xhr});
                xhr.upload.addEventListener("progress", function (event) {
                    dispatch('updatePrintFileProgress', event)
                }, false);
                xhr.open("POST", data.presignedPostData.url, true);
                xhr.send(formData);
                xhr.onload = function () {
                    // clear upload status
                    commit('finishPrintFileUpload');
                    if (this.status === 204) {
                        resolve();
                    } else {
                        reject(this.responseText);
                    }
                };
            })
        },
        cancelPrintFileUpload({commit, state}) {
            if (state.printFileRequest) {
                state.printFileRequest.abort();
            }
            commit('finishPrintFileUpload')
        },
        fetchCampaignTemplates({commit, state, rootGetters}, data = {}) {
            if (state.fetchingCampTemplates || !rootGetters.activeStore.id) {
                return;
            }
            if (state.campTemplatesFetched && !state.moreCampTemplatesAvailable) {
                // No more campaigns available
                return;
            }

            state.fetchingCampTemplates = true;
            // 25 default page size
            const pageSize = data.pageSize ? data.pageSize : 25;

            let url = process.env.VUE_APP_API_URL + "/api/stores/" + rootGetters.activeStore.id + "/campaigntemplates/?page_size=" + pageSize
            if (data.append) {
                // get more results with same params
                url = state.nextCampTemplateUrl;
            } else {
                // not appending so clear everything
                commit('clearCampaignTemplates');
            }

            axios.get(url)
                .then(response => {
                    commit('updateCampaignTemplates', response);
                })
                .catch(function () {
                    state.campTemplatesFetched = true;
                    state.fetchingCampTemplates = false;
                })
        },
        addCampaignTemplate({commit}, data) {
            commit('addCampaignTemplate', data)
        },
        updateCampaignTemplate({commit}, data) {
            commit('updateCampaignTemplate', data)
        },
        fetchSuppliers({commit, state, rootGetters}, data) {
            if (state.fetchingSuppliers) {
                return
            }
            state.fetchingSuppliers = true
            let url = process.env.VUE_APP_API_URL + "/api/stores/" + rootGetters.activeStore.id + "/storesuppliers/"
            if (data && data.filters) {
                url = url + '&' + data.filters
            }

            axios.get(url)
                .then(response => {
                    commit('updateSuppliers', response)
                    if (data && data.pushRoute) {
                        router.push(data.routeLocation)
                    }
                })
                .catch(function() {
                    state.fetchingSuppliers = false
                })
        },
        clearAndFetchSuppliers({commit, dispatch}, data) {
            commit('clearSuppliers')
            dispatch('fetchSuppliers', data)
        },
        clearSuppliers({commit}) {
            commit('clearSuppliers')
        },
    }
}
