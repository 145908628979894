import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

import router from '@/router'

import AdminStore from '../modules/storemanagement/store'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        admin: AdminStore
    },
    state: {
        status: '',
        profile: {user: {}},
        profileFetched: false,
        membership: {},
        membershipFetched: false,
        stores: [],
        products: [],
        leaseproducts: [],
        ccs: [],
        addresses: [],
        groups: [],
        groupsFetched: false,
        fetchingStores: false,
        fetchingFilterOptions: false,
        filterOptionsFetched: false,
        filterOptions: {},
        fetchingProducts: false,
        productsFetched: false,
        moreProductsAvailable: false,
        nextProductUrl: null,
        productFilters: null,
        totalProdCount: null,
        moreLeaseProductsAvailable: false,
        totalLeaseProdCount: null,
        nextLeaseProductUrl: null,
        leaseProductFilters: null,
        fetchingLeaseProducts: false,
        leaseProductsFetched: false,
        productTags: [],
        productTagsFetched: false,
        carriers: [],
        carriersFetched: false,
        fetchingCarriers: false,
        storeCarriers: [],
        storeCarriersFetched: false,
        fetchingStoreCarriers: false,
        carriersInfo: {},
        carriersInfoFetched: false,
        ccsFetched: false,
        addressesFetched: false,
        activeStore: null,
        apiToken: localStorage.getItem('apiToken') || '',
        cart: {
            products: [],
            leaseProducts: [],
            store: null
        },
        sentCart: {
            products: [],
            leaseProducts: [],
            store: null
        },
        orderDetails: {
            address: null,
            cc: null,
            phone: null,
            info: null,
            recipient_name: null,
            recipient_email: null,
            recipient_id: "newRecipient",
            save_recipient: false,
            order_id: null,
            requires_approval: false
        },
        selectedRates: [],
        sentOrder: {},
        cartProductObjects: [],
        cartProductObjectsFetched: false,
        fetchingOrders: false,
        fetchingLeaseOrders: false,
        nextOrderUrl: null,
        nextLeaseOrderUrl: null,
        moreOrdersAvailable: false,
        moreLeaseOrdersAvailable: false,
        totalOrderCount: null,
        totalLeaseOrderCount: null,
        ordersFetched: false,
        leaseOrdersFetched: false,
        orderOptionsFetched: false,
        leaseOrderOptionsFetched: false,
        storeOrders: [],
        storeLeaseOrders: [],
        orderFilters: null,
        leaseOrderFilters: null,
        legacyOrders: [],
        legacyOrdersFetched: false,
        orderFilterOptions: {},
        leaseOrderFilterOptions: {},
        gridView: true,
        categoryFilter: null,
        typeFilter: null,
        nameFilter: null,
        tagFilter: null,

        // Chatlio script related
        chatlioReady: false,
        chatlioUserDataSent: false,

        customerCampaignTemplate: null
    },
    mutations: {
        updateStores(state, stores) {
            state.stores = stores.filter(s => s.status != 3);
            const active = state.stores.find(s => s.path == router.currentRoute.params.storename);
            if (active) {
                this.commit('setActiveStore', active.id);
            } else {
                const firstStore = state.stores[0];
                this.commit('setActiveStore', firstStore.id);
            }
        },
        updateFetchingStores(state, status) {
            state.fetchingStores = status;
        },
        updateProfile(state, profile) {
            state.profile = {...state.profile, ...profile};
            state.profileFetched = true;
        },
        updateMember(state, member) {
            state.membership = {...state.membership, ...member};
            state.membershipFetched = true;
        },
        updateFilterOptions(state, data) {
            state.filterOptions = data;
            state.fetchingFilterOptions = false;
            state.filterOptionsFetched = true;
        },
        updateProducts(state, response) {
            state.products = [...state.products, ...response.data.results];
            state.productsFetched = true;
            state.moreProductsAvailable = response.data.next ? true : false;
            state.totalProdCount = response.data.count;
            state.nextProductUrl = response.data.next;
            state.fetchingProducts = false;
        },
        setProductsFetched(state) {
            state.productsFetched = true;
            state.moreProductsAvailable = false;
            state.totalProdCount = 0;
            state.nextProductUrl = null;
        },
        clearProducts(state) {
            state.productsFetched = false;
            state.moreProductsAvailable = null;
            state.products = [];
            state.totalProdCount = null;
            state.nextProductUrl = null;
            state.productFilters = null;
        },
        updateLeaseProducts(state, response) {
            state.leaseproducts = [...state.leaseproducts, ...response.data.results];
            state.leaseProductsFetched = true;
            state.moreLeaseProductsAvailable = response.data.next ? true : false;
            state.totalLeaseProdCount = response.data.count;
            state.nextLeaseProductUrl = response.data.next;
            state.fetchingLeaseProducts = false;

        },
        setLeasesFetched(state) {
            state.fetchingLeaseProducts = false;
            state.leaseproducts = [];
            state.leaseProductsFetched = true;
            state.moreLeaseProductsAvailable = false;
            state.totalLeaseProdCount = 0;
            state.nextLeaseProductUrl = null;
        },
        clearLeaseProducts(state) {
            state.leaseProductsFetched = false;
            state.moreLeaseProductsAvailable = false;
            state.leaseproducts = [];
            state.totalLeaseProdCount = null;
            state.nextLeaseProductUrl = null;
            state.leaseProductFilters = null;
        },
        updateProductTags(state, tags) {
            state.productTags = tags;
            state.productTags.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
            state.productTagsFetched = true;
        },
        updateCarriers(state, carriers) {
            state.carriers = carriers;
            state.carriers.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
            state.carriersFetched = true;
            state.fetchingCarriers = false;
        },
        clearStoreCarriers(state) {
            state.storeCarriers = []
            state.fetchingStoreCarriers = false
            state.storeCarriersFetched = false
            //state.supplierFilters = null
        },
        updateStoreCarriers(state, storeCarriers) {
            state.storeCarriers = storeCarriers;
            state.storeCarriersFetched = true;
            state.fetchingStoreCarriers = false;
        },
        updateCarriersInfo(state, carriersInfo) {
            state.carriersInfo = carriersInfo;
            state.carriersInfoFetched = true;
        },
        updateCostCenters(state, ccs) {
            state.ccs = ccs;
            state.ccsFetched = true;
        }
        ,
        updateAddresses(state, addresses) {
            state.addresses = addresses;
            state.addresses.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
            state.addressesFetched = true;
        }
        ,
        updateGroups(state, groups) {
            state.groups = groups;
            state.groupsFetched = true;
        }
        ,
        updateStoreOrders(state, response) {
            state.storeOrders = [...state.storeOrders, ...response.data.results];
            state.ordersFetched = true;
            state.moreOrdersAvailable = response.data.next ? true : false;
            state.totalOrderCount = response.data.count;
            state.nextOrderUrl = response.data.next;
            state.fetchingOrders = false;
        }
        ,
        clearOrders(state) {
            state.ordersFetched = false;
            state.moreOrdersAvailable = null;
            state.storeOrders = [];
            state.totalOrderCount = null;
            state.nextOrderUrl = null;
            state.orderFilters = null;
        }
        ,
        clearOrderFilters(state) {
            state.orderOptionsFetched = false;
            state.orderFilterOptions = {};
        }
        ,
        updateStoreLeaseOrders(state, response) {
            state.storeLeaseOrders = [...state.storeLeaseOrders, ...response.data.results];
            state.leaseOrdersFetched = true;
            state.moreLeaseOrdersAvailable = response.data.next ? true : false;
            state.totalLeaseOrderCount = response.data.count;
            state.nextLeaseOrderUrl = response.data.next;
            state.fetchingLeaseOrders = false;
        }
        ,
        clearLeaseOrders(state) {
            state.leaseOrdersFetched = false;
            state.moreLeaseOrdersAvailable = null;
            state.storeLeaseOrders = [];
            state.totalLeaseOrderCount = null;
            state.nextLeaseOrderUrl = null;
            state.leaseOrderFilters = null;
        }
        ,
        clearLeaseOrderFilters(state) {
            state.leaseOrderOptionsFetched = false;
            state.leaseOrderFilterOptions = {};
        }
        ,
        updateLegacyOrders(state, orders) {
            state.legacyOrders = orders;
            state.legacyOrdersFetched = true;
        }
        ,
        updateOrderFilters(state, filterOptions) {
            state.orderFilterOptions = filterOptions;
            state.orderFilterOptions.addresses.sort((a, b) => (a.toLowerCase() > b.toLowerCase()) ? 1 : -1);
            state.orderOptionsFetched = true;
        }
        ,
        updateLeaseOrderFilters(state, filterOptions) {
            state.leaseOrderFilterOptions = filterOptions;
            state.leaseOrderFilterOptions.addresses.sort((a, b) => (a.toLowerCase() > b.toLowerCase()) ? 1 : -1);
            state.leaseOrderOptionsFetched = true;
        }
        ,
        setToken(state, token) {
            state.apiToken = token
            localStorage.setItem('apiToken', token);
        }
        ,
        auth_request(state) {
            state.status = 'loading'
        }
        ,
        auth_success(state, {token, stores}) {
            state.status = 'success'
            state.apiToken = token
            state.stores = stores
        }
        ,
        auth_error(state) {
            state.status = 'error'
        }
        ,
        logout(state) {
            state.status = '';
            state.apiToken = '';
            state.stores = [];
            state.activeStore = null;
            state.products = [];
            state.productsFetched = false;
            state.productTags = [];
            state.productTagsFetched = false;
            state.leaseproducts = [];
            state.leaseProductsFetched = false
            state.ccs = [];
            state.addresses = [];
            state.groups = [];
            state.groupsFetched = false;
            state.ccsFetched = false;
            state.addressesFetched = false,
                state.profile = {};
            state.profileFetched = false;
            state.membership = {};
            state.membershipFetched = false;

            state.storeOrders = [];
            state.ordersFetched = false;
            state.legacyOrders = [];
            state.legacyOrdersFetched = false;
            state.categoryFilter = null;
            state.typeFilter = null;
            state.nameFilter = null;
            state.tagFilter = null;
            state.orderOptionsFetched = false;
            state.orderFilterOptions = {};
            state.leaseOrderOptionsFetched = false;
            state.leaseOrderFilterOptions = {};

            localStorage.setItem('apiToken', '');
            // Clear store admin related data
            this.dispatch('admin/clearStoreAdminData');
        }
        ,
        setActiveStore(state, activeStoreId) {
            const store = state.stores.find(s => s.id == activeStoreId);
            if (store) {
                state.activeStore = store;
                state.products = [];
                state.productsFetched = false;
                state.productTags = [];
                state.productTagsFetched = false;
                store.carriers = [];
                store.carriersFetched = false,
                    state.leaseproducts = [];
                state.leaseProductsFetched = false
                state.ccsFetched = false;
                state.addressesFetched = false,
                    state.ccs = [];
                state.addresses = [];
                state.groups = [];
                state.groupsFetched = false;
                state.membership = {};
                state.membershipFetched = false;
                state.storeOrders = [];
                state.ordersFetched = false;
                state.legacyOrders = [];
                state.legacyOrdersFetched = false;
                state.categoryFilter = null;
                state.typeFilter = null;
                state.nameFilter = null;
                state.tagFilter = null;
                state.orderOptionsFetched = false;
                state.orderFilterOptions = {};
                state.leaseOrderOptionsFetched = false;
                state.leaseOrderFilterOptions = {};
                state.cartProductObjects = [];
                state.cartLeaseProductObjects = [];
                state.cartProductObjectsFetched = false;
                state.chatlioUserDataSent = false;

                // Send store name to HEAP
                if (window.heap) {
                    window.heap.addUserProperties({store: store.name});
                }

                // Clear product/lease product related data
                this.dispatch('clearProducts');
                this.dispatch('clearLeaseProducts');

                // Clear store admin related data
                this.dispatch('admin/clearStoreAdminData');

                // Check if we have cart in localstorage
                let cart = window.localStorage.getItem('cart' + store.id);
                if (cart) {
                    state.cart = JSON.parse(cart);
                    if (!state.cart.leaseProducts) {
                        state.cart.leaseProducts = [];
                    }
                } else {
                    state.cart = {store: store.id, products: [], leaseProducts: []};
                }
                // clear orderDetails
                state.orderDetails = {
                    address: null,
                    cc: null,
                    phone: null,
                    info: null,
                    recipient_name: null,
                    recipient_email: null,
                    recipient_id: "newRecipient",
                    save_recipient: false,
                    order_id: null,
                    requires_approval: false
                }
                // fetch user profile and membership
                this.dispatch('fetchProfile');

                if (store.path != router.currentRoute.params.storename) {
                    // REMOVE WITH NEW UI OR WHEN BIOTEL CONTRACT HAS ENDED
                    if (store.path === 'biotel') {
                        window.location.href = 'https://old.framme.com/biotel';
                    }
                    else {
                        router.push('/' + store.path);
                    }
                }
            }
        }
        ,
        addProductToCart(state, item) {
            let found = state.cart.products.findIndex(function (p) {
                if (p.productId == item.productId) {
                    if (!item.variantId) {
                        return true;
                    } else {
                        return p.variantId == item.variantId ? true : false;
                    }
                }
                return false;
            });
            if (found >= 0) {
                state.cart.products[found].count += item.count;
            } else {
                state.cart.products.push(item);
            }
            state.cartProductObjectsFetched = false;
            this.commit('saveCart');
        }
        ,
        addLeaseProductToCart(state, item) {
            if (!state.cart.leaseProducts) {
                state.cart.leaseProducts = [];
            }
            let found = state.cart.leaseProducts.findIndex(lp => lp.leaseProductId == item.leaseProductId)
            if (found >= 0) {
                // lease product already in cart
                return
            } else {
                state.cart.leaseProducts.push(item);
            }
            state.cartProductObjectsFetched = false;
            this.commit('saveCart');
        }
        ,
        removeProductFromCart(state, item) {
            let found = state.cart.products.findIndex(function (p) {
                if (p.productId == item.productId) {
                    if (!item.variantId) {
                        return true;
                    } else {
                        return p.variantId == item.variantId ? true : false;
                    }
                }
                return false;
            });
            if (found > -1) {
                state.cart.products.splice(found, 1);
                this.commit('saveCart');
            }
        }
        ,
        removeLeaseProductFromCart(state, item) {
            let found = state.cart.leaseProducts.findIndex(function (p) {
                if (p.leaseProductId == item.leaseProductId) {
                    return true;
                }
                return false;
            });
            if (found > -1) {
                state.cart.leaseProducts.splice(found, 1);
                this.commit('saveCart');
            }
        }
        ,
        editProductCount(state, item) {
            let found = state.cart.products.findIndex(function (p) {
                if (p.productId == item.productId) {
                    if (!item.variantId) {
                        return true;
                    } else {
                        return p.variantId == item.variantId ? true : false;
                    }
                }
                return false;
            });
            if (found > -1) {
                state.cart.products[found].count = item.count;
                this.commit('saveCart');
            }
        }
        ,
        addProduct(state, newProduct) {
            state.products.push(newProduct);
        }
        ,
        addLeaseProduct(state, newProduct) {
            state.leaseproducts.push(newProduct);
        }
        ,
        updateProduct(state, updatedProduct) {
            state.products = [...state.products.map(item => item.id !== updatedProduct.id ? item : {...item, ...updatedProduct})]
        }
        ,
        removeProduct(state, updatedProduct) {
            state.products = state.products.filter(p => p.id !== updatedProduct.id);
        }
        ,
        updateLeaseProduct(state, updatedProduct) {
            state.leaseproducts = [...state.leaseproducts.map(item => item.id !== updatedProduct.id ? item : {...item, ...updatedProduct})]
            if (state.leaseOrderOptionsFetched) {
                // update new lease status to lease status filter options
                state.leaseOrderFilterOptions.lease_prod_statuses[updatedProduct.id] = updatedProduct.lease_status;
            }
        }
        ,
        addAddress(state, newAddress) {
            state.addresses.push(newAddress);
            state.addresses.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
        }
        ,
        updateAddress(state, updatedAddress) {
            state.addresses = [...state.addresses.map(item => item.id !== updatedAddress.id ? item : {...item, ...updatedAddress})]
            state.addresses.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
        }
        ,
        addRecipientToAddress(state, newRecipient) {
            let address = state.addresses.find(item => item.id == newRecipient.delivery_address);
            address.recipients.push(newRecipient);
            state.addresses = [...state.addresses.map(item => item.id !== address.id ? item : {...item, ...address})]
        }
        ,
        updateRecipientToAddress(state, updatedRecipient) {
            let address = state.addresses.find(item => item.id == updatedRecipient.delivery_address);
            address.recipients = address.recipients.map(recipient => recipient.id !== updatedRecipient.id ? recipient : {...recipient, ...updatedRecipient})
            state.addresses = [...state.addresses.map(item => item.id !== address.id ? item : {...item, ...address})]
        }
        ,
        deleteRecipientFromAddress(state, {addressId, recipientId}) {
            let address = state.addresses.find(item => item.id == addressId);
            address.recipients = address.recipients.filter(r => r.id != recipientId);
            state.addresses = [...state.addresses.map(item => item.id !== address.id ? item : {...item, ...address})]
        }
        ,
        addCC(state, newCC) {
            state.ccs.push(newCC);
        }
        ,
        updateCC(state, updatedCC) {
            state.ccs = [...state.ccs.map(item => item.id !== updatedCC.id ? item : {...item, ...updatedCC})];
        },
        updateCCs(state, ccs) {
            state.ccs = ccs;
            //state.ccs.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
            state.ccsFetched = true;
        }
        ,
        addGroup(state, newGroup) {
            state.groups.push(newGroup);
        }
        ,
        updateGroup(state, updatedGroup) {
            state.groups = [...state.groups.map(item => item.id !== updatedGroup.id ? item : {...item, ...updatedGroup})];
        }
        ,
        addProductTag(state, newTag) {
            state.productTags.push(newTag);
            state.productTags.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
        }
        ,
        updateProductTag(state, updatedTag) {
            state.productTags = [...state.productTags.map(item => item.id !== updatedTag.id ? item : {...item, ...updatedTag})];
            state.productTags.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
        }
        ,
        deleteProductTag(state, {tagId}) {
            state.productTags = state.productTags.filter(t => t.id != tagId);
            // Remove from products
            let products = state.products;
            for (let product of products) {
                let found = product.tags.findIndex(t => t == tagId)
                if (found > -1) {
                    // rmeove tag from product
                    product.tags.splice(found, 1);
                }
            }
            state.products = [...products];

            // Remove from lease products
            let lproducts = state.leaseproducts;
            for (let lproduct of lproducts) {
                let found = lproduct.tags.findIndex(t => t == tagId)
                if (found > -1) {
                    // rmeove tag from product
                    lproduct.tags.splice(found, 1);
                }
            }
            state.leaseproducts = [...lproducts];
        }
        ,
        updateProductToTags(state, {productId, tagIds, isLeaseProduct}) {
            let tags = state.productTags;
            for (let tag of tags) {
                if (isLeaseProduct) {
                    if (tagIds.includes(tag.id) && !tag.lease_products.includes(productId)) {
                        // add product to tag
                        tag.lease_products.push(productId);
                    } else if (!tagIds.includes(tag.id) && tag.lease_products.includes(productId)) {
                        // remove product from tag
                        tag.lease_products = tag.lease_products.filter(lp => lp != productId);
                    }
                } else {
                    if (tagIds.includes(tag.id) && !tag.products.includes(productId)) {
                        // add product to tag
                        tag.products.push(productId);
                    } else if (!tagIds.includes(tag.id) && tag.products.includes(productId)) {
                        // remove product from tag
                        tag.products = tag.products.filter(lp => lp != productId);
                    }
                }
            }
            state.productTags = [...tags];
        }
        ,
        updateTagToProducts(state, {tagId, productIds, leaseProductIds, productsChanged, leaseProductsChanged}) {
            if (productsChanged) {
                // update all products
                let products = state.products;
                for (let product of products) {
                    if (productIds.includes(product.id) && !product.tags.includes(tagId)) {
                        // add tag to product
                        product.tags.push(tagId);
                    } else if (!productIds.includes(product.id) && product.tags.includes(tagId)) {
                        // remove tag from product
                        product.tags = product.tags.filter(t => t != tagId);
                    }
                }
                state.products = [...products];
            }
            if (leaseProductsChanged) {
                // update all leaseproducts
                let lproducts = state.leaseproducts;
                for (let lproduct of lproducts) {
                    if (leaseProductIds.includes(lproduct.id) && !lproduct.tags.includes(tagId)) {
                        // add tag to product
                        lproduct.tags.push(tagId);
                    } else if (!leaseProductIds.includes(lproduct.id) && lproduct.tags.includes(tagId)) {
                        // remove tag from product
                        lproduct.tags = lproduct.tags.filter(t => t != tagId);
                    }
                }
                state.leaseproducts = [...lproducts];
            }
        }
        ,
        updateVariant(state, updatedVariant) {
            let product = state.products.find(item => item.id == updatedVariant.product);
            if (product) {
                // if products have been fetched to catalog
                product.variants = product.variants.map(variant => variant.id !== updatedVariant.id ? variant : {...variant, ...updatedVariant})
                state.products = [...state.products.map(item => item.id !== product.id ? item : {...item, ...product})]
            }
            let aProduct = state.admin.products.find(item => item.id == updatedVariant.product);
            if (aProduct) {
                // if products have been fetched to store management
                aProduct.variants = aProduct.variants.map(variant => variant.id !== updatedVariant.id ? variant : {...variant, ...updatedVariant})
                state.admin.products = [...state.admin.products.map(item => item.id !== aProduct.id ? item : {...item, ...aProduct})]
            }
        }
        ,
        removeVariant(state, data) {
            let product = state.products.find(item => item.id == data.productId);
            if (product) {
                // if products have been fetched to catalog
                if (data.removeAll) {
                    product.variants = [];
                } else {
                    let found = product.variants.findIndex(v => v.id == data.variantId)
                    if (found > -1) {
                        product.variants.splice(found, 1);
                    }
                }
                state.products = [...state.products.map(item => item.id !== product.id ? item : {...item, ...product})]
            }
            let aProduct = state.admin.products.find(item => item.id == data.productId);
            if (aProduct) {
                // if products have been fetched to catalog
                if (data.removeAll) {
                    aProduct.variants = [];
                } else {
                    let found = aProduct.variants.findIndex(v => v.id == data.variantId)
                    if (found > -1) {
                        aProduct.variants.splice(found, 1);
                    }
                }
                state.admin.products = [...state.admin.products.map(item => item.id !== aProduct.id ? item : {...item, ...aProduct})]
            }
        }
        ,
        updateStore(state, updatedStore) {
            state.stores = [...state.stores.map(item => item.id !== updatedStore.id ? item : {...item, ...updatedStore})];
            // also update activeStore if needed
            if (state.activeStore.id == updatedStore.id) {
                state.activeStore = {...state.activeStore, ...updatedStore};
            }
        }
        ,
        saveCart(state) {
            window.localStorage.setItem('cart' + state.activeStore.id, JSON.stringify(state.cart));
        }
        ,
        updateOrder(state, updated) {
            state.orderDetails = {...state.orderDetails, [updated.key]: updated.value};
        }
        ,
        updateSelectedRates(state, selectedRates) {
            state.selectedRates = [...selectedRates]
        }
        ,
        clearSelectedRates(state) {
         state.selectedRates = []
        }
        ,
        addSentOrder(state, newOrder) {
            state.storeOrders = [newOrder, ...state.storeOrders];
        }
        ,
        copyAndClearCart(state) {
            state.sentOrder = {...state.orderDetails};
            state.sentCart.products = [...state.cart.products];
            state.sentCart.leaseProducts = [...state.cart.leaseProducts]
            state.sentCart.store = state.cart.store;
            state.cart = {products: [], leaseProducts: [], store: null};
            state.orderDetails = {
                address: null,
                cc: null,
                phone: null,
                info: null,
                recipient_name: null,
                recipient_email: null,
                recipient_id: "newRecipient",
                save_recipient: false,
                order_id: null,
                requires_approval: false
            }
            state.cartProductObjectsFetched = false;
            this.commit('saveCart');
        }
        ,
        clearSentOrder(state) {
            state.sentOrder = {};
            state.sentCart = {
                products: [],
                leaseProducts: [],
                store: null,
                selectedRates: []
            };
        }
        ,
        updateGridListView(state, data) {
            state.gridView = data;
        }
        ,
        updateFilters(state, data) {
            switch (data.name) {
                case 'category':
                    state.categoryFilter = data.value;
                    break;
                case 'type':
                    state.typeFilter = data.value;
                    break;
                case 'name':
                    state.nameFilter = data.value;
                    break;
                case 'tags':
                    state.tagFilter = data.value;
                    break;
            }
        }
        ,
        updateSentOrder(state, data) {
            state.storeOrders = [...state.storeOrders.map(item => item.id !== data.id ? item : {...item, ...data})]
        }
        ,
        updateLeaseOrder(state, data) {
            const order = state.storeOrders.find(o => o.id == data.order)
            if (order) {
                order.lease_products = [...order.lease_products.map(item => item.id !== data.id ? item : {...item, ...data})]
            }

            // remove order id since there is full order data in object
            delete data.order;
            state.storeLeaseOrders = [...state.storeLeaseOrders.map(lo => lo.id !== data.id ? lo : {...lo, ...data})];
        }
        ,
        reorderProductList(state, data) {
            state.products = data;
        }
        ,
        setCartProductObjects(state, data) {
            state.cartProductObjects = data;

            // update cart products after fetching product objects
            let clearProducts = false;
            let prods = state.cart.products.filter(function (cartProd) {
                if (cartProd.variantId) {
                    // Check if product and variant exist
                    const prod = state.cartProductObjects.find(p => p.status == 2 && p.id == cartProd.productId)
                    if (!prod) {
                        clearProducts = true;
                        return false;
                    }
                    const variant = prod.variants.find(v => v.id == cartProd.variantId)
                    if (!variant) {
                        clearProducts = true;
                        return false;
                    }
                    return true;
                } else {
                    // Check if product exists
                    let prod = state.cartProductObjects.find(p => p.status == 2 && p.id == cartProd.productId);
                    if (!prod) {
                        clearProducts = true;
                        return false;
                    }
                    return true;
                }
            })
            // reset cart.products
            state.cart.products = prods;
            if (clearProducts) {
                // Clear products from catalog so they are refetched if something removed from cart
                this.commit('clearProducts');
            }
            this.commit('saveCart');
        }
        ,
        setCartLeaseProductObjects(state, data) {
            state.cartLeaseProductObjects = data;

            let clearProducts = false;
            // check lease cart products
            let leaseProds = state.cart.leaseProducts.filter(function (cartLP) {
                // Check if leaseproduct exists, is in stock and published
                let lp = state.cartLeaseProductObjects.find(lp => lp.status == 2 && lp.id == cartLP.leaseProductId && lp.lease_status == 1)
                if (!lp) {
                    clearProducts = true;
                    return false;
                }
                return true;
            })
            state.cart.leaseProducts = leaseProds;
            if (clearProducts) {
                // Clear lease products from catalog so they are refetched if something removed from cart
                this.commit('clearLeaseProducts');
            }
            this.commit('saveCart');
            state.cartProductObjectsFetched = true;
        }
        ,
        chatlioReady(state) {
            state.chatlioReady = true;
        }
        ,
        chatlioSendUserData(state) {
            const userId = "user" + state.profile.user.id;
            let userName = state.profile.user.first_name + " " + state.profile.user.last_name
            const storeName = state.activeStore ? state.activeStore.name : "";
            if (storeName.length) {
                userName = storeName + " " + state.profile.user.first_name + " " + state.profile.user.last_name;
            }
            window._chatlio.identify(userId, {
                name: userName,
                store: storeName
            });
            state.chatlioUserDataSent = true;
        }
        ,
        updateStripeStatus(state, data) {
            state.activeStore.stripe_connection = {...state.activeStore.stripe_connection, ...data}
        }
        ,
        setCustomerCampaignTemplate(state, templateData) {
            state.customerCampaignTemplate = templateData;
        }
        ,
    },
    getters: {
        isLoggedIn: state => !!state.apiToken,
        profile: state => state.profile,
        membership: state => state.membership,
        membershipFetched: state => state.membershipFetched,
        isManager: state => state.membership.role ? state.membership.role == 1 ? true : false : null, // OLD VALUE, REMOVE THIS!!!
        isAdmin: state => state.membership && state.membership.group_data ? state.membership.group_data.admin_group : false,
        isStaff: state => state.profile && state.profile.user.is_staff ? true : false,
        authStatus: state => state.status,
        apiToken: state => state.apiToken,
        hasStores: state => state.stores.length > 0,
        allStores: state => state.stores,
        activeStore: state => state.activeStore ? state.activeStore : {},
        stripeInitiated: state => state.activeStore && state.activeStore.stripe_connection ? true : false,
        stripeEnabled: state => state.activeStore && state.activeStore.stripe_connection && state.activeStore.stripe_connection.connected ? true : false,
        currencyStr: state => state.activeStore && state.activeStore.currency_display ? state.activeStore && state.activeStore.currency_display : "",
        storeFeatures: state => state.activeStore ? state.activeStore.features : {},
        productsFetched: state => state.productsFetched,
        leaseProductsFetched: state => state.leaseProductsFetched,
        leaseProductsEnabled: (state, getters) => getters.storeFeatures && getters.storeFeatures.leased_products ? true : false,
        addressesFetched: state => state.addressesFetched,
        ccsFetched: state => state.ccsFetched,
        groupsFetched: state => state.groupsFetched,
        groups: state => state.groups,
        nonAdminGroups: state => state.groups.filter(g => g.admin_group == false),
        groupNameDict: state => state.groups.reduce((a, g) => ({...a, [g.id]: g.name}), {}),
        products: state => state.products,
        leaseproducts: state => state.leaseproducts,
        productTags: state => state.productTags,
        carriers: state => state.carriers,
        storeCarriers: state => state.storeCarriers,
        storeCarriersFetched: state => state.storeCarriersFetched,
        carriersInfo: state => state.carriersInfo,
        carriersInfoFetched: state => state.carriersInfoFetched,
        productsList: state => state.products.filter(p => p.status == 2),
        gridView: state => state.gridView,
        nameFilterOptions: state => {
            let prods = [];
            let lease_prods = [];
            if (state.filterOptions.products) {
                prods = state.filterOptions.products.map(p => ({id: "normal_" + p.id, name: p.name}))
            }
            if (state.filterOptions.lease_products) {
                lease_prods = state.filterOptions.lease_products.map(p => ({id: "lease_" + p.id, name: p.name}))
            }
            return [...prods, ...lease_prods];
        },
        nameFilter: state => state.nameFilter,
        categoryFilter: state => state.categoryFilter,
        typeFilter: state => state.typeFilter,
        tagFilter: state => state.tagFilter,
        productFilterParams: state => {
            let params = "";
            if (state.categoryFilter) {
                params = params + "&c=" + state.categoryFilter;
            }
            if (state.typeFilter) {
                params = params + "&t=" + state.typeFilter;
            }
            if (state.tagFilter && state.tagFilter.length) {
                const tagParams = state.tagFilter.map(t => "&tag[]=" + t).join("")
                params = params + tagParams;
            }
            return params;
        },
        productIdFilterParams: state => {
            if (state.nameFilter && state.nameFilter.startsWith('normal')) {
                // ID filter is set for regular product
                const id = state.nameFilter.split('_').find(n => n != "normal")
                const params = "&id[]=" + id;
                return params;
            }
            return "";
        },
        leaseProductIdFilterParams: state => {
            if (state.nameFilter && state.nameFilter.startsWith('lease')) {
                // ID filter is set for lease product
                const id = state.nameFilter.split('_').find(n => n != "lease")
                const params = "&id[]=" + id;
                return params;
            }
            return "";
        },
        cartItemCount: state => state.cart.products.reduce((acc, obj) => acc + obj.count, 0) + state.cart.leaseProducts.reduce((acc, obj) => acc + obj.count, 0),
        cartItems: state => state.cart.products,
        cartProductObjects: state => state.cartProductObjects,
        cartLeaseItems: state => state.cart.leaseProducts,
        sentItems: state => state.sentCart.products,
        sentLeaseItems: state => state.sentCart.leaseProducts,
        cartTotal: state => {
            if (!state.cartProductObjectsFetched) {
                return 0.00
            }
            const total = state.cart.products.reduce((acc, obj) => acc + (obj.count * state.cartProductObjects.find(p => p.id == obj.productId).price), 0);
            return total.toFixed(2);
        },
        sentTotal: state => {
            if (!state.productsFetched) {
                return 0.00
            }
            const total = state.sentCart.products.reduce((acc, obj) => acc + (obj.count * state.products.find(p => p.id == obj.productId).price), 0);
            return total.toFixed(2);
        },
        costcenters: state => state.ccs,
        addresses: state => state.addresses,
        orderDetails: state => state.orderDetails,
        selectedRates: state => state.selectedRates,
        sentOrder: state => state.sentOrder,
        ordersFetched: state => state.ordersFetched,
        storeOrders: state => state.storeOrders,
        legacyOrders: state => state.legacyOrders,
        customerCampaignTemplate: state => state.customerCampaignTemplate,
        orderOptionsFetched: state => state.orderOptionsFetched,
        orderFilterOptions: state => state.orderFilterOptions,
    },
    actions: {
        login({commit, dispatch}, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({url: process.env.VUE_APP_API_URL + "/api/stores/login/", data: user, method: 'POST'})
                    .then(resp => {
                        const token = resp.data.token
                        const stores = resp.data.stores
                        dispatch('loginSetup', {token: token, stores: stores})
                            .then(function () {
                                resolve(resp)
                            })
                    })
                    .catch(err => {
                        commit('auth_error')
                        localStorage.removeItem('apiToken')
                        reject(err)
                    })
            })
        },
        loginSetup({commit}, data) {
            return new Promise((resolve) => {
                const token = data.token
                const stores = data.stores
                localStorage.setItem('apiToken', token)
                axios.defaults.headers.common['Authorization'] = "Token " + token;
                Vue.prototype.$http.defaults.headers.common['Authorization'] = "Token " + token;
                commit('auth_success', {token, stores});
                resolve();
            })
        },
        logout({commit}) {
            return new Promise((resolve) => {
                commit('logout')
                localStorage.removeItem('apiToken')
                delete axios.defaults.headers.common['Authorization']
                resolve()
            })
        },
        fetchProfile({commit, dispatch}) {
            axios({url: process.env.VUE_APP_API_URL + "/api/users/details/", method: 'GET'})
                .then(resp => {
                    const profile = resp.data
                    commit('updateProfile', profile);
                    dispatch('fetchCarriers')
                    dispatch('fetchMembership');
                    dispatch('chatlioSendUserData');
                })
                .catch(function () {
                })
        },
        fetchMembership({commit, state, dispatch}) {
            if (!state.activeStore || !state.activeStore.id) {
                dispatch('fetchStores');
            } else {
                axios({
                    url: process.env.VUE_APP_API_URL + "/api/stores/" + state.activeStore.id + "/members/membership/",
                    method: 'GET'
                })
                    .then(resp => {
                        const membership = resp.data
                        commit('updateMember', membership)
                    })
                    .catch(function () {
                    })
            }
        },
        fetchStores({commit, state, dispatch}) {
            if (state.fetchingStores) {
                return;
            } else {
                commit('updateFetchingStores', true);
            }
            axios({url: process.env.VUE_APP_API_URL + "/api/stores/", method: 'GET'})
                .then(resp => {
                    const stores = resp.data
                    commit('updateStores', stores);
                    dispatch('fetchProfile');
                    commit('updateFetchingStores', false);
                })
                .catch(function () {
                    commit('updateFetchingStores', false);
                    // Redirect user to login page
                    let redirect_path = null;
                    if (router.currentRoute.params.storename) {
                        redirect_path = router.currentRoute.params.storename + "/login";
                    } else {
                        redirect_path = "login"
                    }
                    router.push('/' + redirect_path);
                })
        },
        fetchFilterOptions({commit, state}) {
            if (state.fetchingFilterOptions) {
                return;
            }
            if (!state.activeStore || !state.activeStore.id) {
                return;
            }
            state.fetchingOptions = true;
            axios({
                url: process.env.VUE_APP_API_URL + "/api/stores/" + state.activeStore.id + "/products/filteroptions/",
                method: 'GET'
            })
                .then(response => {
                    commit('updateFilterOptions', response.data);
                })
                .catch(function () {
                    state.fetchingOptions = false;
                })
        },
        fetchProducts({commit, state, getters, dispatch}, data = {}) {
            if (state.fetchingProducts) {
                return;
            }
            if (state.productsFetched && !state.moreProductsAvailable) {
                // No more products available
                return;
            }
            if (!state.activeStore || !state.activeStore.id) {
                dispatch('fetchStores');
            } else {
                state.fetchingProducts = true;
                let url = process.env.VUE_APP_API_URL + "/api/stores/" + state.activeStore.id + "/products/?page_size=24";
                if (data.append) {
                    // get more results with same params
                    url = state.nextProductUrl;
                } else {
                    // not appending so clear everything
                    commit('clearProducts');
                    if (getters.productFilterParams.length || getters.productIdFilterParams) {
                        state.productFilters = getters.productFilterParams + getters.productIdFilterParams
                    }
                }
                if (getters.productFilterParams.length || getters.productIdFilterParams) {
                    url = url + getters.productFilterParams + getters.productIdFilterParams;
                }

                axios.get(url)
                    .then(response => {
                        commit('updateProducts', response);
                        if (!state.productTagsFetched) {
                            dispatch('fetchTags');
                        }
                    })
                    .catch(function () {
                        state.fetchingProducts = false;
                    })
            }
        },
        clearProducts({commit}) {
            //commit('clearProductFilters');
            commit('clearProducts');
        },
        clearAndFetchProducts({commit, dispatch, state}, data) {
            if (state.fetchingProducts) {
                return;
            }
            commit('clearProducts');
            if (state.nameFilter) {
                if (state.nameFilter.startsWith('lease')) {
                    // Only single lease product is requested
                    // set regular products as fetched
                    commit('setProductsFetched');

                    // fetch lease products
                    dispatch('fetchLeaseProducts', data);
                } else {
                    // Only single product is requested
                    // set lease products as fetched
                    commit('setLeasesFetched');

                    // fetch lease products
                    dispatch('fetchProducts', data);
                }
            } else {
                dispatch('fetchProducts', data);
            }
        },
        fetchLeaseProducts({commit, state, getters, dispatch}, data = {}) {
            if (state.fetchingLeaseProducts) {
                return;
            }
            if (state.leaseProductsFetched && !state.moreLeaseProductsAvailable) {
                // No more lease products available
                return;
            }
            if (!state.activeStore || !state.activeStore.id) {
                dispatch('fetchStores');
            } else {
                if (state.activeStore.features.leased_products) {
                    state.fetchingLeaseProducts = true;
                    let url = process.env.VUE_APP_API_URL + "/api/stores/" + state.activeStore.id + "/leaseproducts/?page_size=24"
                    if (data.append) {
                        // get more results with same params
                        url = state.nextLeaseProductUrl;
                    } else {
                        // not appending so clear everything
                        commit('clearLeaseProducts');
                        if (getters.productFilterParams.length || getters.leaseProductIdFilterParams) {
                            state.leaseProductFilters = getters.productFilterParams + getters.leaseProductIdFilterParams
                        }
                    }
                    if (getters.productFilterParams.length || getters.leaseProductIdFilterParams) {
                        url = url + getters.productFilterParams + getters.leaseProductIdFilterParams;
                    }
                    axios.get(url)
                        .then(response => {
                            commit('updateLeaseProducts', response);
                            if (!state.productTagsFetched) {
                                dispatch('fetchTags');
                            }
                        })
                        .catch(function () {
                            state.fetchingLeaseProducts = false;
                        })
                } else {
                    commit('setLeasesFetched');
                }
            }
        },
        clearLeaseProducts({commit}) {
            //commit('clearProductFilters');
            commit('clearLeaseProducts');
        },
        fetchTags({commit, state, dispatch}) {
            if (!state.activeStore || !state.activeStore.id) {
                dispatch('fetchStores');
            } else {
                axios({
                    url: process.env.VUE_APP_API_URL + "/api/stores/" + state.activeStore.id + "/tags/",
                    method: 'GET'
                })
                    .then(resp => {
                        const tags = resp.data;
                        commit('updateProductTags', tags);
                    })
                    .catch(function () {
                    })
            }
        },
        fetchCarriers({commit, state, dispatch}) {
            if (!state.activeStore || !state.activeStore.id) {
                dispatch('fetchStores');
            } else {
                axios({url: process.env.VUE_APP_API_URL + "/api/carrier/", method: 'GET'})
                    .then(resp => {
                        const carriers = resp.data;
                        commit('updateCarriers', carriers);
                    })
                    .catch(function () {
                    })
            }
        },
        async fetchStoreCarriers({commit, state, dispatch}, data) {
            if (state.fetchingStoreCarriers) {
                return
            }
            state.fetchingStoreCarriers = true
            try {
                const resp = await axios.get(
                    process.env.VUE_APP_API_URL + "/api/stores/" + state.activeStore.id + "/carriers/"
                );
                const storeCarriers = resp.data;
                commit('updateStoreCarriers', storeCarriers);
                dispatch("updateCarriersInfo")
                if (data && data.pushRoute) {
                    await router.push(data.routeLocation)
                }
            } catch (error) {
                state.fetchingStoreCarriers = false
                // Handle error
            }
        },
        clearAndFetchStoreCarriers({commit, dispatch}, data) {
            commit('clearStoreCarriers')
            dispatch('fetchStoreCarriers', data)
        },
        updateCarriersInfo({commit, state}) {
            const carriers = state.carriers;
            const storeCarriers = state.storeCarriers;
            const carriersInfo = {};
            storeCarriers.forEach((storeCarrier) => {
                const carrierId = storeCarrier.carrier;
                const foundCarrier = carriers.find((carrier) => carrier.id === carrierId);
                if (foundCarrier) {
                    const storeCarrierAccounts = storeCarrier.carrieraccounts_set;
                    storeCarrierAccounts.forEach((carrierAccount) => {
                        const carrierLabel = carrierAccount.custom_label;
                        carriersInfo[carrierLabel] = foundCarrier;
                    })
                }
            });
            commit('updateCarriersInfo', carriersInfo);
        },
        fetchCostCenters({commit, dispatch, state}) {
            if (!state.activeStore || !state.activeStore.id) {
                dispatch('fetchStores');
            } else {
                axios({
                    url: process.env.VUE_APP_API_URL + "/api/stores/" + state.activeStore.id + "/store_cc/",
                    method: 'GET'
                })
                    .then(resp => {
                        const ccs = resp.data
                        commit('updateCostCenters', ccs)
                    })
                    .catch(function () {
                    })
            }
        },
        fetchAddresses({commit, dispatch, state}) {
            if (!state.activeStore || !state.activeStore.id) {
                dispatch('fetchStores');
            } else {
                axios({
                    url: process.env.VUE_APP_API_URL + "/api/stores/" + state.activeStore.id + "/store_addresses/",
                    method: 'GET'
                })
                    .then(resp => {
                        const addresses = resp.data
                        commit('updateAddresses', addresses)
                    })
                    .catch(function () {

                    })
            }
        },
        fetchGroups({commit, state}) {
            if (!state.activeStore || !state.activeStore.id || state.groupsFetched) {
                return;
            } else {
                axios({
                    url: process.env.VUE_APP_API_URL + "/api/stores/" + state.activeStore.id + "/usergroups/",
                    method: 'GET'
                })
                    .then(resp => {
                        const groups = resp.data
                        commit('updateGroups', groups)
                    })
                    .catch(function () {
                    })
            }
        },
        fetchOrders({commit, state}, data) {
            if (state.fetchingOrders) {
                return;
            }
            if (state.ordersFetched && !state.moreOrdersAvailable) {
                // No more orders available
                // TODO: HANDLE FETCHING LEGACY ORDERS
                return;
            }

            state.fetchingOrders = true;
            let url = process.env.VUE_APP_API_URL + "/api/stores/" + state.activeStore.id + "/orders/?page_size=25"
            if (data && data.append) {
                // get more results with same params
                url = state.nextOrderUrl;
            } else if (data && data.filters) {
                // not appending ie first batch and including filters --> save filters
                state.orderFilters = data.filters;
            }
            if (data && data.filters) {
                url = url + '&' + data.filters;
            }

            axios.get(url)
                .then(response => {
                    commit('updateStoreOrders', response);
                })
                .catch(function () {
                    state.fetchingOrders = false;
                })
        },
        clearAndFetchOrders({commit, dispatch, state}, data) {
            if (state.fetchingOrders) {
                return;
            }
            commit('clearOrders');
            dispatch('fetchOrders', data);
        },
        clearOrders({commit}) {
            commit('clearOrderFilters');
            commit('clearOrders');
        },
        fetchLeaseOrders({commit, state}, data) {
            if (state.fetchingLeaseOrders) {
                return;
            }
            if (state.leaseOrdersFetched && !state.moreLeaseOrdersAvailable) {
                // No more lease orders available
                return;
            }

            state.fetchingLeaseOrders = true;
            let url = process.env.VUE_APP_API_URL + "/api/stores/" + state.activeStore.id + "/leaseorderlist/?page_size=25"
            if (data.append) {
                // get more results with same params
                url = state.nextLeaseOrderUrl;
            } else if (data.filters) {
                // not appending ie first batch and including filters --> save filters
                state.leaseOrderFilters = data.filters;
            }
            if (data.filters) {
                url = url + '&' + data.filters;
            }
            axios.get(url)
                .then(response => {
                    commit('updateStoreLeaseOrders', response);
                })
                .catch(function () {
                    state.fetchingLeaseOrders = false;
                })
        },
        clearAndFetchLeaseOrders({commit, dispatch, state}, data) {
            if (state.fetchingLeaseOrders) {
                return;
            }
            commit('clearLeaseOrders');
            dispatch('fetchLeaseOrders', data);
        },
        clearLeaseOrders({commit}) {
            commit('clearLeaseOrderFilters');
            commit('clearLeaseOrders');
        },
        fetchLegacyOrders({commit, state}) {
            if (!state.activeStore || !state.activeStore.id || state.legacyOrdersFetched) {
                return;
            } else {
                axios({
                    url: process.env.VUE_APP_API_URL + "/api/stores/" + state.activeStore.id + "/legacyorders/",
                    method: 'GET'
                })
                    .then(resp => {
                        const orders = resp.data
                        commit('updateLegacyOrders', orders)
                    })
                    .catch(function () {
                    })
            }
        },
        fetchOrderOptions({commit, state}) {
            if (!state.activeStore || !state.activeStore.id) {
                return
            } else {
                axios({
                    url: process.env.VUE_APP_API_URL + "/api/stores/" + state.activeStore.id + "/orders/filteroptions/",
                    method: 'GET'
                })
                    .then(resp => {
                        const orderFilterOptions = resp.data
                        commit('updateOrderFilters', orderFilterOptions)
                    })
                    .catch(function () {

                    })
            }
        },
        fetchLeaseOrderOptions({commit, state}) {
            if (!state.activeStore || !state.activeStore.id) {
                return
            } else {
                axios({
                    url: process.env.VUE_APP_API_URL + "/api/stores/" + state.activeStore.id + "/orders/leasefilteroptions/",
                    method: 'GET'
                })
                    .then(resp => {
                        const orderFilterOptions = resp.data
                        commit('updateLeaseOrderFilters', orderFilterOptions)
                    })
                    .catch(function () {

                    })
            }
        },
        addSentOrder({commit}, data) {
            commit('addSentOrder', data);
        },
        copySentOrderAndClearCart({commit}) {
            commit('copyAndClearCart');
        },
        clearSentOrderData({commit}) {
            commit('clearSentOrder');
        },
        addProduct({commit, dispatch}, data) {
            commit('addProduct', data);
            // Thumbnails are created async, so re-fetch product to get new thumb urls
            setTimeout(function () {
                dispatch('updateProductThumbs', data);
            }, 2000);
        },
        addLeaseProduct({commit, dispatch}, data) {
            commit('addLeaseProduct', data);
            // Thumbnails are created async, so re-fetch product to get new thumb urls
            setTimeout(function () {
                dispatch('updateLeaseProductThumbs', data);
            }, 2000);
        },
        updateProduct({commit, dispatch}, data) {
            commit('updateProduct', data);
            // Thumbnails are created async, so re-fetch product to get new thumb urls
            if (data.hasOwnProperty('id')) {
                setTimeout(function () {
                    dispatch('updateProductThumbs', data);
                }, 2000);
            }
        },
        removeProduct({commit}, data) {
            commit('removeProduct', data);
        },
        updateLeaseProduct({commit, dispatch}, data) {
            commit('updateLeaseProduct', data);
            // Thumbnails are created async, so re-fetch product to get new thumb urls
            setTimeout(function () {
                dispatch('updateLeaseProductThumbs', data);
            }, 2000);
        },
        updateProductThumbs({commit, state}, productData) {
            axios({
                url: process.env.VUE_APP_API_URL + "/api/stores/" + state.activeStore.id + "/products/" + productData.id + "/",
                method: 'GET'
            })
                .then(resp => {
                    commit('updateProduct', resp.data);
                })
                .catch(function () {

                })
        },
        updateLeaseProductThumbs({commit, state}, productData) {
            axios({
                url: process.env.VUE_APP_API_URL + "/api/stores/" + state.activeStore.id + "/leaseproducts/" + productData.id + "/",
                method: 'GET'
            })
                .then(resp => {
                    commit('updateLeaseProduct', resp.data);
                })
                .catch(function () {

                })
        },
        addAddress({commit}, data) {
            commit('addAddress', data);
        },
        updateAddress({commit}, data) {
            commit('updateAddress', data);
        },
        removeAddress({commit, state}, data) {
            const addresses = state.addresses.filter(a => a.id != data.address_id);
            commit('updateAddresses', addresses);
        },
        addRecipientToAddress({commit}, data) {
            commit('addRecipientToAddress', data);
        },
        updateRecipientToAddress({commit}, data) {
            commit('updateRecipientToAddress', data);
        },
        deleteRecipientFromAddress({commit}, data) {
            commit('deleteRecipientFromAddress', data);
        },
        addCC({commit}, data) {
            commit('addCC', data);
        },
        updateCC({commit}, data) {
            commit('updateCC', data);
        },
        removeCC({commit, state}, data) {
            const cost_centers = state.ccs.filter(a => a.id != data.cc_id);
            commit('updateCCs', cost_centers);
        },
        addGroup({commit}, data) {
            commit('addGroup', data);
        },
        updateGroup({commit}, data) {
            commit('updateGroup', data);
        },
        addProductTag({commit}, data) {
            commit('addProductTag', data);
        },
        updateProductTag({commit}, data) {
            commit('updateProductTag', data);
        },
        deleteProductTag({commit}, data) {
            commit('deleteProductTag', data);
        },
        updateProductToTags({commit}, data) {
            commit('updateProductToTags', data);
        },
        updateTagToProducts({commit}, data) {
            commit('updateTagToProducts', data);
        },
        updateVariant({commit}, data) {
            commit('updateVariant', data)
        },
        removeVariant({commit}, data) {
            commit('removeVariant', data)
        },
        updateStore({commit}, data) {
            commit('updateStore', data);
        },
        updateGridListView({commit}, data) {
            commit('updateGridListView', data);
        },
        updateProductFilter({commit}, data) {
            commit('updateFilters', data);
        },
        updateSentOrder({commit}, data) {
            commit('updateSentOrder', data);
        },
        updateLeaseOrder({commit}, data) {
            commit('updateLeaseOrder', data);
        },
        reorderProductList({commit, state}, data) {
            return new Promise((resolve) => {
                commit('reorderProductList', data);
                const newProductOrder = data.map((p, index) => {
                    return {id: p.id, index: index}
                })
                axios({
                    url: process.env.VUE_APP_API_URL + "/api/stores/" + state.activeStore.id + "/reorder-products/",
                    method: 'POST',
                    data: {productorder: newProductOrder}
                })
                    .then(function () {
                        resolve();
                    })
                    .catch(function () {
                        resolve();
                    })
            })
        },
        fetchCartProductObjects({dispatch, commit, state}) {
            // Fetch product objects for products in cart
            if (!state.activeStore || !state.activeStore.id) {
                dispatch('fetchStores');
            } else {
                const ids = state.cart.products.map(p => p.productId);
                const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + state.activeStore.id + '/products/';
                axios({url: api_url, method: 'GET', params: {id: ids}})
                    .then(resp => {
                        dispatch('fetchCartLeaseProductObjects');
                        commit('setCartProductObjects', resp.data.results);
                    })
                    .catch(function () {

                    })
            }
        },
        fetchCartLeaseProductObjects({dispatch, commit, state, getters}) {
            // Fetch lease product objects for products in cart
            if (!state.activeStore || !state.activeStore.id) {
                dispatch('fetchStores');
            } else if (!getters.storeFeatures.leased_products) {
                // lease products disabled
                commit('setCartLeaseProductObjects', []);
            } else {
                const ids = state.cart.leaseProducts.map(p => p.leaseProductId);
                if (ids.length) {
                    const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + state.activeStore.id + '/leaseproducts/';
                    axios({url: api_url, method: 'GET', params: {id: ids}})
                        .then(resp => {
                            commit('setCartLeaseProductObjects', resp.data.results);
                        })
                        .catch(function () {
                        })
                } else {
                    commit('setCartLeaseProductObjects', []);
                }
            }
        },
        chatlioReady({commit, state}) {
            if (!state.chatlioReady) {
                commit('chatlioReady');
            }
        },
        chatlioSendUserData({commit, state}) {
            if (state.chatlioReady && !state.chatlioUserDataSent && state.profileFetched) {
                commit('chatlioSendUserData');
            }
        },
        fetchStripeStatus({commit, state}) {
            return new Promise((resolve) => {
                if (!state.activeStore || !state.activeStore.id) {
                    return
                }
                const api_url = process.env.VUE_APP_API_URL + '/api/payments/store/' + state.activeStore.id + '/status/';
                axios({url: api_url, method: 'GET', params: {}})
                    .then(resp => {
                        commit('updateStripeStatus', resp.data);
                        resolve();
                    })
                    .catch(function () {
                        resolve();
                    })
            })
        },
        setCustomerCampaignTemplate({commit}, data) {
            commit('setCustomerCampaignTemplate', data)
        }
    }
})
